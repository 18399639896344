import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {StaticImage} from 'gatsby-plugin-image';

import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import styled from '@emotion/styled';

import SEO from '../../components/common/SEO';
import ProductHero from '../../components/common/ProductHero';
import ProductPageContainer from '../../components/common/ProductPageContainer';
import {H4, Hero, Link, P, P2} from '../../components/common/Typography';
import {breakpoints, colors, ContentContainer, fontWeights} from '../../styles/theme';
import {CTAArrow} from '../../components/common/Clickables';
import {ContrastContext} from '../../helpers/context';
import OmnichannelBackground from '../../images/product/terminal/omnichannel-background.png';
import AnimatedBackground from '../../components/common/AnimatedBackground';



/*
 * Constants
 */
/* eslint-disable-next-line i18next/no-literal-string */
const videoUrl = 'https://player.vimeo.com/video/548850274';


/*
 * Private Elements
 */
const EmbraceOmnichannelContainer = styled.div`
    padding-top: 150px;
    padding-bottom: 350px;
    background: ${colors.transparent} url(${OmnichannelBackground}) no-repeat scroll right top;
    background-size: cover;

    ${ContentContainer} {
        text-align: center;
        max-width: 850px;
        margin: auto;

        ${Hero} {
            margin-bottom: 25px;
        }
    }
`;

const StyledFeaturesContainer = styled.div`
    color: ${colors.white};
    position: relative;
    margin-top: -300px;

    .image {
        position: relative;
        width: 100%;
        padding-left: 25px;
        padding-right: 25px;
        z-index: 5;
    }

    .content-wrapper {
        clip-path: polygon(0 150px, 100% 0, 100% 100%, 0% 100%);
        // Why 50vw (aka 50% the view width)? 
        // Because the image before the slant is 100% view width, its height is auto based on that.
        // We want to cross the slant in the middle of the height of the image: 50% of 100vw = 50vw
        margin-top: -50vw;
        padding-top: calc(50vw + 2rem);

        ${ContentContainer} {
            padding-bottom: 100px;

            .title {
                margin-bottom: 35px;
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .image {
            width: 60%;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
            padding-right: 0;
        }

        .content-wrapper {
            width: 100%;
            padding-left: 65px;
            padding-right: 65px;
            clip-path: polygon(0 200px, 100% 0, 100% 100%, 0% 100%);
            margin-top: -30vw;
            padding-top: calc(30vw + 2rem);

            ${ContentContainer} {
                margin-top: 20px;

                .title {
                    text-align: center;
                    max-width: 700px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        .content-wrapper {
            clip-path: polygon(0 300px, 100% 0, 100% 100%, 0% 100%);
        }
    }
`;

const StyledTerminalFeatureCard = styled(Link)`
    color: ${colors.white};
    background-color: ${colors.black};
    box-shadow: 0 2px 10px 0 ${colors.black};
    text-align: left;
    margin: 15px 0;
    padding: 20px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover,
    &:focus {
        color: ${colors.white};
        text-decoration: none;
    }

    .logo {
        left: 0;
        height: 50px;
        width: 50px;
        margin: 0 0 15px 0;
    }

    .link {
        text-align: right;
    }

    ${P} {
        text-transform: uppercase;
        font-weight: ${fontWeights.bold};
        letter-spacing: 3px;
        margin-bottom: 10px;
    }

    @media (min-width: ${breakpoints.md}) {
        min-height: 300px;
        margin: 20px;
        height: calc(100% - 40px);  // 40px is the total padding (top + bottom)
    }
`;

const TerminalFeatureCard = ({image, title, content, to}) => {
    const [linkIsHovered, setLinkIsHovered] = useState(false);

    // eslint-disable-next-line i18next/no-literal-string
    const className = linkIsHovered ? 'hover' : '';

    return (
        <StyledTerminalFeatureCard
            to={to}
            onMouseEnter={() => setLinkIsHovered(true)}
            onMouseLeave={() => setLinkIsHovered(false)}
        >
            <div className="content">
                <div className="logo">{image}</div>
                <P>{title}</P>
                <P2>{content}</P2>
            </div>
            <div className="link">
                <CTAArrow classNames={className} colored />
            </div>
        </StyledTerminalFeatureCard>
    );
};

TerminalFeatureCard.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

const StyledComingSoonCard = styled.div`
    color: ${colors.white};
    background-color: ${colors.transparent};
    box-shadow: inset 6px 2px 40px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    margin: 15px 0;
    padding: 20px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 3px dashed ${colors.black};

    ${P} {
        text-transform: uppercase;
        font-weight: ${fontWeights.semiBold};
        letter-spacing: 3px;
        margin-bottom: 10px;
    }

    @media (min-width: ${breakpoints.md}) {
        min-height: 300px;
        margin: 20px;
        height: calc(100% - 40px);  // 40px is the total padding (top + bottom)
    }
`;

const ComingSoonCard = () => (
    <StyledComingSoonCard>
        <P><Trans>Coming soon</Trans></P>
    </StyledComingSoonCard>
);

const FeaturesContainer = () => {
    const {t} = useTranslation();

    // Cards content
    const processingCardContent = t('Access 50+ local payment methods and local payment processors — online and '
        + 'in-store — to provide customers a more convenient shopping experience while keeping a personalized checkout '
        + 'process.');
    const dynamicRoutingCardContent = t('Deploy additional payment methods without any changes in the code. By not '
        + 'having to rely on the development department anymore, the go-to-market accelerates.');
    const riskCardContent = t('Set precise rules to flag, block, or dynamically apply 3D Secure to certain '
        + 'transactions. Even on in-store payments.');
    const vaultCardContent = t('With the Switch ability to create vaults, you can securely store and tokenize payment '
        + 'details and customers’ data. All while being compliant with the latest data regulations across the globe, '
        + 'including GDPR.');
    const dynamicFormsCardContent = t('Deploy additional payment methods without any changes in the code. By not having'
        + ' to rely on the development department anymore,  the go-to-market accelerates.');
    const reconciliationCardContent = t('Identify fee mismatches and reconciliation errors across multiple payment '
        + 'providers, bank accounts, and platforms, including your in-store payments.');
    const analyticsCardContent = t('Access all data from your payments operation right on the Switch Dashboard. Our '
        + 'system collects all transaction data and makes it searchable. This way you can follow your payments '
        + 'lifecycle and find areas for improvement.');
    const omnichannelCardContent = t('Manage your entire terminal fleet. Register any new terminal, monitor its '
        + 'activity, and manage all configurations remotely on the dashboard.');

    return (
        <StyledFeaturesContainer>
            <div className="image">
                <StaticImage
                    src="../../images/product/terminal/omnichannel.png"
                    placeholder="blurred"
                    alt=""
                />
            </div>

            <AnimatedBackground className="content-wrapper">
                <ContentContainer>
                    <div className="title">
                        <H4>
                            <Trans>All our features are available for both online and in-store payments.</Trans>
                        </H4>
                    </div>

                    <div className="row no-gutters content">
                        <div className="col-12 col-md-6 col-lg-4">
                            <TerminalFeatureCard
                                image={(
                                    <StaticImage
                                        src="../../images/product/processing/logo-mobile.png"
                                        placeholder="blurred"
                                        height={50}
                                        alt=""
                                    />
                                )}
                                title={t('Processing')}
                                content={processingCardContent}
                                to="/product/processing"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <TerminalFeatureCard
                                image={(
                                    <StaticImage
                                        src="../../images/product/dynamic-routing/logo-mobile.png"
                                        placeholder="blurred"
                                        height={50}
                                        alt=""
                                    />
                                )}
                                title={t('Dynamic Routing')}
                                content={dynamicRoutingCardContent}
                                to="/product/dynamic-routing"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <TerminalFeatureCard
                                image={(
                                    <StaticImage
                                        src="../../images/product/risk/logo-mobile.png"
                                        placeholder="blurred"
                                        height={50}
                                        alt=""
                                    />
                                )}
                                title={t('Risk')}
                                content={riskCardContent}
                                to="/product/risk"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <TerminalFeatureCard
                                image={(
                                    <StaticImage
                                        src="../../images/product/vault/logo-mobile.png"
                                        placeholder="blurred"
                                        height={50}
                                        alt=""
                                    />
                                )}
                                title={t('Vault')}
                                content={vaultCardContent}
                                to="/product/vault"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <TerminalFeatureCard
                                image={(
                                    <StaticImage
                                        src="../../images/product/dynamic-forms.png"
                                        placeholder="blurred"
                                        height={50}
                                        alt=""
                                    />
                                )}
                                title={t('Dynamic Forms')}
                                content={dynamicFormsCardContent}
                                to="https://switchpayments.com/docs/dynamic-forms"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <TerminalFeatureCard
                                image={(
                                    <StaticImage
                                        src="../../images/product/reconciliation/logo-mobile.png"
                                        placeholder="blurred"
                                        height={50}
                                        alt=""
                                    />
                                )}
                                title={t('Reconciliation')}
                                content={reconciliationCardContent}
                                to="/product/reconciliation"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <TerminalFeatureCard
                                image={(
                                    <StaticImage
                                        src="../../images/product/analytics/logo-mobile.png"
                                        placeholder="blurred"
                                        height={50}
                                        alt=""
                                    />
                                )}
                                title={t('Analytics')}
                                content={analyticsCardContent}
                                to="/product/analytics"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <TerminalFeatureCard
                                image={(
                                    <StaticImage
                                        src="../../images/product/terminal/logo-mobile.png"
                                        placeholder="blurred"
                                        height={50}
                                        alt=""
                                    />
                                )}
                                title={t('Omnichannel')}
                                content={omnichannelCardContent}
                                to="https://switchpayments.com/docs/terminal"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <ComingSoonCard />
                        </div>
                    </div>
                </ContentContainer>
            </AnimatedBackground>
        </StyledFeaturesContainer>
    );
};


/*
 * Public Elements
 */
const TerminalPage = () => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    const section = t('Terminal');
    // eslint-disable-next-line i18next/no-literal-string
    const sectionStr = 'Terminal';
    const hero = t('Unify your payments stack across every channel.');
    const description = t(
        'Manage your entire terminal fleet and e-commerce operations through a single infrastructure.',
    );
    const image = (
        <StaticImage src="../../images/product/terminal/logo.png" placeholder="none" loading="eager" alt="" />
    );
    const mobileImage = (
        <StaticImage src="../../images/product/terminal/logo-mobile.png" placeholder="none" loading="eager" alt="" />
    );

    return (
        <>
            <SEO
                title={section}
                ogTitle={t('Cloud-based POS Terminal Application - Switch')}
                description={t('Leverage your payment stack for a truly omnichannel experience. Choose which terminal '
                    + 'vendors, acquirers, and acquirer processors you want to work with.')}
                image="/website/metatags/terminal.png"
            />

            <ProductHero
                section={section}
                sectionStr={sectionStr}
                hero={hero}
                description={description}
                image={image}
                mobileImage={mobileImage}
                videoUrl={videoUrl}
            />

            <ProductPageContainer>
                <EmbraceOmnichannelContainer>
                    <ContentContainer>
                        <Hero><Trans>Embrace a truly omnichannel experience.</Trans></Hero>
                    </ContentContainer>
                </EmbraceOmnichannelContainer>

                <FeaturesContainer />
            </ProductPageContainer>
        </>
    );
};


/*
 * Exports
 */
export default TerminalPage;
